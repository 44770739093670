import mastercard from 'assets/images/sponsors/mastercard.png';
import accenture from 'assets/images/sponsors/accenture.png';
import assetnote from 'assets/images/sponsors/assetnote.png';
import atlassian from 'assets/images/sponsors/atlassian.png';
import cybercx from 'assets/images/sponsors/cybercx.png';
import google from 'assets/images/sponsors/google.png';
import hackerone from 'assets/images/sponsors/hackerone.png';
import hacklabs from 'assets/images/sponsors/hacklabs.png';
import puresecurity from 'assets/images/sponsors/pure-security.png';
import secolve from 'assets/images/sponsors/secolve.png';
import skylightcyber from 'assets/images/sponsors/skylight-cyber.png';
import tesserent from 'assets/images/sponsors/tesserent.png';
import sekuro from 'assets/images/sponsors/sekuro.png';
import canva from 'assets/images/sponsors/canva.png';
import pepsico from 'assets/images/sponsors/pepsico.png';
import tanto from 'assets/images/sponsors/tanto.png';
import gridware from 'assets/images/sponsors/gridware.png';
import Sponsor from 'types/Sponsor';

/* template for a sponsor object - replace fields with sponsor details */
// export const SponsorName: Sponsor = {
//   name: '',
//   description: '',
//   logo: '',
//   href: '',
//   color: '',
// };

export const GOOGLE: Sponsor = {
  name: 'Google',
  description:
    'Google is a global technology leader focused on improving the ways people connect with information. Google Security & Privacy Engineering builds secure software solutions, conducts cutting-edge research on the diverse security and privacy needs of users and use a wealth of tools, languages, and frameworks.  Our mission is to keep Google and its millions of users safe, secure, and happy.',
  logo: google,
  href: 'http://g.co/SecurityPrivacyEngJobs',
  color: '#E94235',
};

export const ATLASSIAN: Sponsor = {
  name: 'Atlassian',
  description:
    'Behind every great human achievement, there is a team. From medicine and space travel, to disaster response and pizza deliveries, our products help teams all over the planet advance humanity through the power of software. Our mission is to help unleash the potential of every team through open work.',
  logo: atlassian,
  href: 'https://www.atlassian.com/company/careers',
  color: '#0052CC',
};

export const PURESECURITY: Sponsor = {
  name: 'Pure Security',
  description:
    'Based in Australia and servicing clients globally, Pure Security provides proven expertise to help mid to enterprise sized organisations, and all levels of government, strengthen and maintain their security. With over 180 security professionals, located across 5 offices, and with a comprehensive range of cybersecurity services, we assist from the basement to the boardroom!',
  logo: puresecurity,
  href: 'https://pure.security/work-with-us/',
  color: '#4BE3E3',
};

export const TESSERENT: Sponsor = {
  name: 'Tesserent',
  description:
    'Australia’s largest ASX-listed cybersecurity company,  Tesserent provides proven expertise to help mid to enterprise sized organisations, and all levels of government, strengthen and maintain their security. With over 400 security professionals, located across 8 offices, and with a comprehensive range of cybersecurity services, we assist from the basement to the boardroom!',
  logo: tesserent,
  href: 'https://investors.tesserent.com/site/careers/why-work-for-tesserent',
  color: '#009467',
};

export const SKYLIGHTCYBER: Sponsor = {
  name: 'Skylight Cyber',
  description:
    'Skylight Cyber is thrilled to announce its support of Australia’s future cybersecurity talent through a platinum sponsorship of UNSW’s Security Society. Other than pizza money, we will contribute knowledge in the form of workshops and talks covering real world red teaming, reverse engineering and exploit development.',
  logo: skylightcyber,
  href: 'https://skylightcyber.com/about-us/',
  color: '#009DE0',
};

export const SECOLVE: Sponsor = {
  name: 'Secolve',
  description:
    "Secolve is Australia’s next generation Operational Technology (OT) cyber security company. We secure the nation's critical infrastructure from attacks, working with powerplants, substations, water supply, factories and grain silos as examples. We are one of a few niche players in the exciting space of OT security in Australia and our team is growing!",
  logo: secolve,
  href: 'https://www.secolve.com/',
  color: '#39006B',
};

export const ASSETNOTE: Sponsor = {
  name: 'Assetnote',
  description:
    'Assetnote was founded in 2018 with a mission to create a modern, innovative cyber security company that brings the value of the hacker mindset to organisations across the world. As leaders in Attack Surface Management our products are used by companies all around the world, from innovative startups to Fortune 100 companies. Every day we are monitoring hundreds of thousands of assets to help protect our customers from compromise.',
  logo: assetnote,
  href: 'https://assetnote.io/company/careers.html',
  color: '#1674BC',
};

export const CYBERCX: Sponsor = {
  name: 'CyberCX',
  description:
    'CyberCX has united Australia and New Zealand’s most trusted cyber security companies to deliver the most comprehensive end to end cyber security services offering to enterprises and governments. With a workforce of over 700 cyber security professionals and a footprint of over 20 offices across Australia and New Zealand and global presence in Europe and US, CyberCX offers a full suite of cyber security services.',
  logo: cybercx,
  href: 'https://cybercx.com.au/careers/',
  color: '#6AA7FF',
};

export const ACCENTURE: Sponsor = {
  name: 'Accenture',
  description:
    "Accenture solves our clients' toughest challenges by providing unmatched services in strategy, consulting, digital, technology, operations and Security. We partner with more than three-quarters of the Fortune Global 500, driving innovation to improve the way the world works, lives, works and grows. With expertise across more than 40 industries and all business functions, we deliver transformational outcomes for a demanding new digital world.",
  logo: accenture,
  href: 'https://www.accenture.com/us-en/careers/explore-careers/area-of-interest/cybersecurity-careers',
  color: '#A100FF',
};

export const HACKLABS: Sponsor = {
  name: 'PS&C SECURITY | HACKLABS',
  description:
    'PS+C Security partner with clients to help predict, detect, respond and prevent cyber security threats. We protect your business reputation and client data.',
  logo: hacklabs,
  href: 'https://www.hacklabs.com/',
  color: '#575891',
};

export const HACKERONE: Sponsor = {
  name: 'HackerOne',
  description:
    'HackerOne is the most trusted hacker-powered security platform, helping organisations implementing basics of a vulnerability disclosure process, supercharging their existing security programs via a bug bounty program.',
  logo: hackerone,
  href: 'https://www.hackerone.com/careers',
  color: '#494749',
};

export const MASTERCARD: Sponsor = {
  name: 'MasterCard',
  description:
    'As a global technology company in the payments industry, Mastercard’s mission is to connect and power an inclusive, digital economy that benefits everyone, everywhere, by making transactions safe, simple, smart and accessible.',
  logo: mastercard,
  href: 'https://www.mastercard.com.au/en-au.html',
  color: '#F79E1B',
};

export const SEKURO: Sponsor = {
  name: 'Sekuro',
  description:
    'Sekuro’s mission is to be our clients most trusted cyber security partner. Through the delivery of impactful outcomes that build resilience, we enable innovation and increase competitiveness. From the excellence of our delivery to the integrity of our people, we put care and everything we do and see every client as a #clientforlife.',
  logo: sekuro,
  href: 'https://sekuro.io/careers/',
  color: '#C6007E',
};

export const CANVA: Sponsor = {
  name: 'Canva',
  description:
    'Launched in 2013, Canva is an online design and publishing tool with a mission to empower everyone in the world to design anything and publish anywhere.',
  logo: canva,
  href: 'https://www.canva.com/careers/',
  color: '#00C4CC',
};

export const PEPSICO: Sponsor = {
  name: 'PepsiCo',
  description:
    'PepsiCo is one of the world’s leading food and beverage companies serving more than 200 countries and territories around the world.',
  logo: pepsico,
  href: 'https://www.pepsicojobs.com/main',
  color: '#00529c',
};

export const TANTO: Sponsor = {
  name: 'Tanto Security',
  description:
    'Tanto Security was founded on the principle of fairness, with a mission to enhance organizations’ defenses through advanced cybersecurity services. Tanto aims to build a culture that rewards consultants for their contributions and encourages the development of their capabilities.',
  logo: tanto,
  href: 'https://tantosec.com/careers/',
  color: '#FF0000',
};

export const GRIDWARE: Sponsor = {
  name: 'Gridware',
  description:
    'Our mission is to help people, governments and businesses around the world defend themselves against cybercrime and reduce their risk exposure in an always-connected world. And with this emerging risk facing all businesses that move to become digital, it creates an imperative for a new kind of security hero.',
  logo: gridware,
  href: 'https://www.gridware.com.au/careers/',
  color: '#2E5A93',
};
