import {
  ACCENTURE,
  ASSETNOTE,
  ATLASSIAN,
  CYBERCX,
  GOOGLE,
  HACKERONE,
  HACKLABS,
  MASTERCARD,
  PURESECURITY,
  SECOLVE,
  SKYLIGHTCYBER,
  TESSERENT,
  SEKURO,
  CANVA,
  PEPSICO,
  TANTO,
  GRIDWARE,
} from './sponsorsData';

const SPONSORS = [
  {
    year: 2018,
    tiers: [
      {
        level: 'Gold',
        sponsors: [HACKLABS],
      },
      {
        level: 'Silver',
        sponsors: [GOOGLE],
      },
    ],
  },
  {
    year: 2019,
    tiers: [
      {
        level: 'Platinum',
        sponsors: [HACKLABS],
      },
      {
        level: 'Gold',
        sponsors: [HACKERONE, ATLASSIAN],
      },
      {
        level: 'Silver',
        sponsors: [ACCENTURE],
      },
    ],
  },
  {
    year: 2020,
    tiers: [
      {
        level: 'Platinum',
        sponsors: [GOOGLE, PURESECURITY, ATLASSIAN, SKYLIGHTCYBER],
      },
    ],
  },
  {
    year: 2021,
    tiers: [
      {
        level: 'Platinum',
        sponsors: [GOOGLE, TESSERENT, SKYLIGHTCYBER],
      },
      {
        level: 'Gold',
        sponsors: [SECOLVE, ASSETNOTE],
      },
      {
        level: 'Silver',
        sponsors: [ATLASSIAN, CYBERCX],
      },
    ],
  },
  {
    year: 2022,
    tiers: [
      {
        level: 'Diamond',
        sponsors: [SKYLIGHTCYBER],
      },
      {
        level: 'Platinum',
        sponsors: [GOOGLE],
      },
      {
        level: 'Gold',
        sponsors: [SECOLVE, ASSETNOTE, MASTERCARD],
      },
      {
        level: 'Silver',
        sponsors: [ATLASSIAN, CYBERCX],
      },
    ],
  },
  {
    year: 2023,
    tiers: [
      {
        level: 'Platinum',
        sponsors: [GOOGLE],
      },
      {
        level: 'Gold',
        sponsors: [MASTERCARD, SEKURO],
      },
      {
        level: 'Silver',
        sponsors: [ATLASSIAN, CANVA, PEPSICO, SECOLVE],
      },
    ],
  },
  {
    year: 2024,
    tiers: [
      {
        level: 'Gold',
        sponsors: [TANTO, MASTERCARD, GOOGLE],
      },
      {
        level: 'Silver',
        sponsors: [ATLASSIAN, GRIDWARE],
      },
    ],
  },
];

export default SPONSORS;
